<template>
  <div>
    <content-section :spacing="false" class="px-6 py-6 mt-2 rounded-b">
      <div
        class="grid grid-cols-1 gap-y-4 lg:grid-cols-3 md:grid-cols-1 lg:gap-x-4 lg:gap-y-0 "
      >
        <div v-if="data.card_data.cards.length > 0">
          <PaymentCardCredit
            :title="`ADDED CARDS`"
            :active="isActive('card')"
            :currencySymbol="currencySymbol"
            :totalDeposited="data.card_data.total_amount_to_wallet"
            :totalSpent="data.card_data.total_amount_spend"
            :totalTrips="data.card_data.total_trips"
            :cards="data.card_data.cards"
            :fullName="fullName"
          />
        </div>

        <div v-if="data.pass_data">
          <PaymentCardPass
            :title="`OTO PASS`"
            :active="isActive('pass')"
            :currencySymbol="currencySymbol"
            :currentBalance="data.pass_data.balance"
            :totalSpent="data.pass_data.total_spent"
            :totalTrips="data.pass_data.total_trips"
            :facilities="data.pass_data.package.facilities"
            :expiryDate="data.pass_data.expire_date"
            :packageTitle="data.pass_data.package.title"
          />
        </div>

        <div v-if="data.wallet_data">
          <PaymentCardWallet
            :title="`OTO WALLET`"
            :active="isActive('wallet')"
            :wallets="data.financial_accounts"
            @addBalance="$emit('addBalance')"
            @deductBalance="$emit('deductBalance')"
          />
        </div>
      </div>
    </content-section>

    <div class="w-full my-3 border-t"></div>

    <TimelineGroup :logs="logs" :info-column-preset="'riderPaymentMethod'" />
    <InfiniteLoading @infinite="fetchLogs" />
  </div>
</template>

<script>
import TimelineGroup from '@/components/timeline-new/TimelineGroup.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { useEndpoints } from '@/composables'

export default {
  name: 'PaymentMethodScreen',

  components: {
    ContentSection: () => import('@/components/layout/ContentSection.vue'),
    PaymentCardPass: () => import('./blocks/PaymentCardPassBlock.vue'),
    PaymentCardWallet: () => import('./blocks/PaymentCardWalletBlock.vue'),
    PaymentCardCredit: () => import('./blocks/PaymentCardCreditBlock.vue'),
    TimelineGroup,
    InfiniteLoading,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      logs: [],
      isFetchingLogs: false,
    }
  },

  computed: {
    getLogsEndpoint() {
      const offset = this.logs.length || 0
      return (
        useEndpoints.rider.paymentLogs(this.$route.params.id) +
        `?limit=10&offset=${offset}`
      )
    },
  },
  methods: {
    isActive(method) {
      return this.data.default === method
    },

    async fetchLogs($state = null) {
      this.isFetchingLogs = true

      await this.$http
        .get(this.getLogsEndpoint)
        .then((res) => {
          this.logs = this.logs.concat(res.data?.data)
          console.log('fetchLogs res = ', res.data.data.length)

          if ($state) {
            if (res?.data?.meta?.count?.total > this.logs.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.error('fetchLogs err = ', err, err.response.data)
        })
        .finally(() => (this.isFetchingLogs = false))
    },
  },
}
</script>

<style lang="scss"></style>
